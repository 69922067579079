import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { addDays, subDays, format, isWeekend } from 'date-fns';
import  de  from 'date-fns/locale/de';
import en  from 'date-fns/locale/en-US';

const mapStateToProps = (state) => ({
    daterange : state.daterange,
    calendarFilter: state.calendarfilter
})

const mapDispatchToProps = dispatch => ({
    //bring global action to local action
 });

export const DateRange = (props) => {
    const state_data = props.state_data;
    const color = props.color;
    const today = new Date();
    const [calendarAvailability, setCalendarAvailability] = useState([]);
    useEffect(() => {
        if(props.calendarFilter.calendar_availability.length > 0){
            const availability = [];
            props.calendarFilter.calendar_availability.forEach((item, index) => {
                //console.log(item.date);
                availability[item.date] = item;                
            });
            setCalendarAvailability(availability);
            //console.log(availability);
        }
    },[props.calendarFilter.calendar_availability])
    
    /*
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    */
    const [dateRange, setDateRange] = useState([]);
    //setDateRange(null);
    /*
    useEffect(() => {
      console.log("dateRange", dateRange)
    }, [dateRange])
    */

    const customDayContent = (day) => {
        var dayKey = format(day, 'dd.MM.yyyy');        
        var prevDay = subDays(day, 1);
        var nextDay = addDays(day, 1);
        var prevDayKey = format(prevDay, 'dd.MM.yyyy');
        var nextDayKey = format(nextDay, 'dd.MM.yyyy');
        //console.log('calendar',day, dayKey, prevDayKey, nextDayKey);
        var dayPrice = null;
        var custClass = '';
        if(typeof calendarAvailability[dayKey] !== 'undefined'){
            if(calendarAvailability[dayKey].available === 0){
                dayPrice = (<div className='tn-redtxt'> {calendarAvailability[dayKey].price },- </div>); 
            } else {
                dayPrice = (<div className='tn-greentxt'> { calendarAvailability[dayKey].price },- </div>); 
            }
        }

        if(typeof calendarAvailability[dayKey] !== 'undefined' ){
            //console.log(dayKey, prevDayKey, nextDayKey);
            if(calendarAvailability[dayKey].available > 0){                
                custClass = 'tn-greenbg';
                if((typeof calendarAvailability[prevDayKey] !== 'undefined' 
                    && calendarAvailability[prevDayKey].available === 0)
                    || typeof calendarAvailability[prevDayKey] === 'undefined'){
                    custClass += ' green-start';
                }
                if((typeof calendarAvailability[nextDayKey] !== 'undefined' 
                    && calendarAvailability[nextDayKey].available === 0)){
                    custClass += ' green-end';
                }
            } else if(calendarAvailability[dayKey].available === 0){
                custClass = 'tn-redbg';
                if((typeof calendarAvailability[prevDayKey] !== 'undefined' 
                    && calendarAvailability[prevDayKey].available > 0)
                    || typeof calendarAvailability[prevDayKey] === 'undefined'){
                    custClass += ' red-start';
                }
                if((typeof calendarAvailability[nextDayKey] !== 'undefined' 
                    && calendarAvailability[nextDayKey].available > 0)){
                    custClass += ' red-end';
                }
            }
        } else {
            dayPrice = (<div className='loading-img'></div>); 
        }

        if(typeof calendarAvailability[dayKey] === 'undefined'){
            //console.log(dayKey, " not found");
        }
        
        /*
        if (isWeekend(day)) {
            dayPrice = (<div className='greentxt'>730,-</div>);
            custClass = 'greenbg'
        }
        */
        
        
        return (
            <div className={custClass + (props.showprice === 'yes' ? '' : ' big')}>
                <span>{format(day, "d")}</span>
                {props.showprice === 'yes' ? dayPrice : ''}
            </div>
        )
    }

    var after_three_months = new Date();
    after_three_months.setDate(after_three_months.getDate() + 90);

    
    return (
        <DateRangePicker
            //onChange={item => setDateRange([item.selection])}
            onChange={item => setDateRange([])}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={dateRange}
            maxDate={after_three_months}
            direction="horizontal"
            dayContentRenderer={customDayContent}
            showMonthAndYearPickers={false}
            showPreview={false}
            dateDisplayFormat="dd.MM.yy"
            showDateDisplay={false}
            fixedHeight={true}
            staticRanges={[]}
            inputRanges={[]}
            rangeColors={[color, '#000']}
            preventSnapRefocus={true}
            disabled={true}
            locale={props.lang === 'en' || props.lang === ' en' ? en : de}
            className="tnwdrange"
        />
    )
}

DateRange.propTypes = {
    color: PropTypes.string,
}



export default connect(mapStateToProps, mapDispatchToProps)(DateRange)