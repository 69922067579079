import React, { useState, useEffect, useRef, useContext, Component } from 'react';
import CalendarFilter from './CalendarFilter';
import { setReduxWidgetSettingsAction } from '../actions/globalAction';
import { setCalendarAvailabilityAction, setCalendarRoomTypeAction, setCalendarPersonNumberAction, setCalendarCateringAction } from '../actions/tnPriceCalendarAction';
import doAuth from './api/auth';
import getWidgetSettings from './api/widgetsettings';
import getPriceAvailable from './api/priceavailable';
import DateRange from './DateRange';
import { connect, useSelector } from 'react-redux'
import { addDays, format } from 'date-fns';
import Language from '../language/language.json';

const mapStateToProps = state => ({
    //bring global state to local state
    state_data : state,
});

const mapDispatchToProps = dispatch => ({
    //bring global action to local action
    setReduxWidgetSettings: (payload) => dispatch(setReduxWidgetSettingsAction(payload)),
    setCalendarAvailability: (payload) => dispatch(setCalendarAvailabilityAction(payload)),
    setRoomType: (payload) => dispatch(setCalendarRoomTypeAction(payload)),
    setCateringId: (payload) => dispatch(setCalendarCateringAction(payload)),
 });

function TnPriceCalendar(props) {
    const [authToken, setAuthToken] = useState([]);
    const [calendarAvailability, setCalendarAvailability] = useState([]);

    const[isloading, setIsLoading] = useState(false);

    const setRoomType = (v) => {
        props.setRoomType(v);
    }
    const setCateringId = (v) => {
        props.setCateringId(v);
    }

    useEffect(() => {
        if (authToken.length == 0) {
            (async () => {
                // get access token
                await doAuth(props.host, props.token, props.uuid).then((data) => {
                    localStorage.setItem('authtoken', JSON.stringify(data));
                });

                var authtoken = JSON.parse(localStorage.getItem('authtoken'));

                //get widget settings
                await getWidgetSettings(props.host, authtoken.token.token, authtoken.params.id, authtoken.params.module).then((data) => {
                    //console.log(data);
                    props.setReduxWidgetSettings(data);
                    //props.setCalendarFilter(data);
                });


            })();
        }
    }, [])

    useEffect(() => {
        if(typeof props.state_data.global.widgetSettings.params !== 'undefined'){
            setRoomType(props.state_data.global.widgetSettings.params.resources[0].id);
            setCateringId(props.state_data.global.widgetSettings.params?.caterings[0]?.id);
        }
    },[props.state_data.global.widgetSettings]);

    useEffect(() => {        
        (async () => {
            var authtoken = JSON.parse(localStorage.getItem('authtoken'));            
            var days = 30;

            if(parseInt(props.state_data.calendarfilter.calendar_personNumber) > 0 &&
                props.state_data.calendarfilter.calendar_roomType) {
                setIsLoading(true);    
                var calendar_availability = [];
                var cdate = new Date();
                for(var i=1; i<=3; i++){   
                    //cdate.setDate(cdate.getDate() + ((i-1)*days)); 
                    var start = addDays(cdate, ((i-1)*days));
                    var postdata = {
                        "start": format(start, 'dd.MM.yyyy'),
                        "days": days,
                        "resource_id": props.state_data.calendarfilter.calendar_roomType,
                        "persons": parseInt(props.state_data.calendarfilter.calendar_personNumber)
                    }
                    if(props.state_data.calendarfilter.calendar_cateringId > 0){
                        postdata = {...postdata,"catering":parseInt(props.state_data.calendarfilter.calendar_cateringId)}
                    }
                    await getPriceAvailable(props.host, authtoken.token.token, authtoken.params.id, postdata).then((data) => {
                        if(typeof data.error === 'undefined' && data[0].dates.length > 0){
                            calendar_availability = [...calendar_availability, ...data[0].dates];
                            props.setCalendarAvailability(calendar_availability);
                        }
                    });
                }
            }
            setIsLoading(false);
        })()
    }, [
        props.state_data.calendarfilter.calendar_roomType, 
        props.state_data.calendarfilter.calendar_personNumber, 
        props.state_data.calendarfilter.calendar_cateringId, 
        ])
    return (
        <>
            <div className='pricelist_box'>
                <div className='pricelist_box_top'>
                    <h3 className='pricelist_box_title'>{Language[props.lang].GettingThere}</h3>
                    <div className='select_box_area'>

                        <div className='select_box_area_left'>
                            <CalendarFilter lang={props.lang} isloading={isloading} />
                        </div>

                    </div>
                </div>
                <div className='pricelist_box_middle'>
                    <DateRange color='#ccc' lang={props.lang} showprice={props.showprice} />
                </div>
                <div className='pricelist_box_bottom'>
                    <p>{Language[props.lang].PricePerCatAndNightExTax}</p>
                </div>
            </div>
        </>
    )
}

TnPriceCalendar.propTypes = {

}

export default connect(mapStateToProps, mapDispatchToProps)(TnPriceCalendar)
