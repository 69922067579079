import { API_ROOT, default_host} from ".";
var api_url = API_ROOT;
const getPriceAvailable = async (host, bearerToken, id, postData) => {
    if(host){
        api_url = api_url.replace('{host}',host);
    } else {
        api_url = api_url.replace('{host}',default_host);
    }
    const form_api_url = api_url + '/widgets/' + id + '/prices/entitiy';

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(postData)
    };

    try {
        const response = await fetch(form_api_url, requestOptions);
        if (response.ok) {
            const json = await response.json();
            return json;
        }
        else {
            return { resource_id: postData.resource_id, error: 'Not Found' }
        }


    } catch (error) {
        console.log(error);
    }
};

export default getPriceAvailable;