import React from "react";
import * as ReactDOMClient from "react-dom/client";
import ReactDOM from 'react-dom/client';
import reactToWebComponent from "react-to-webcomponent";
import TnPriceCalendar from './components/TnPriceCalendar';
import TnPriceView from './components/TnPriceView';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux'
import configureStore from "./store/configureStore";
import styles from './App.css';

const store = configureStore();

const Index = (props) => {
    const type = props.Type;
    const lang = props.Lang ? props.Lang : 'de';
    const token = props.Token;
    const color = props.Color;
    const uuid = '36d61457-a5da-483d-81d5-bb0bbbb933c1';
    const anfragenlink = props.Anfragenlink;
    const buchenlink = props.Buchenlink;
    const host = props.Host;
    const showprice = props.Showprice;
    return (

        <Provider store={store}>

            <div styles={styles}>
                {
                    type == 'calendar' || type == 'Calendar'
                        ? <TnPriceCalendar lang={lang} token={token} uuid={uuid} color={color} host={host} showprice={showprice} ></TnPriceCalendar>
                        : <TnPriceView lang={lang} token={token} uuid={uuid} color={color} anfragenlink={anfragenlink} buchenlink={buchenlink} host={host} showprice={showprice}></TnPriceView>
                }
            </div>

        </Provider>

    );
};


Index.propTypes = {
    lang: PropTypes.string,
    token: PropTypes.string,
    uuid: PropTypes.string,
    color: PropTypes.string,
    type: PropTypes.string,
    host: PropTypes.string,
    showprice: PropTypes.string,
    anfragenlink: PropTypes.string,
    buchenlink: PropTypes.string
}


customElements.define(
    "tn-price-widget",
    reactToWebComponent(Index, React, ReactDOM, {
        props: ["Lang", "Token", "Uuid", "Color", "Type", "Host", "Showprice", "Anfragenlink", "Buchenlink"],
    }),
)