import React, { useState, useCallback } from 'react';
import { SelectDatepicker } from 'react-select-datepicker';
import { addDays, format } from 'date-fns';
import { connect, useSelector } from 'react-redux';
import { setSelectedDateAction } from '../../actions/datepickerAction';

const mapStateToProps = (state) => ({
    state_data: state
})

const mapDispatchToProps = dispatch => ({
    setReduxSelectedDate: (payload) => dispatch(setSelectedDateAction(payload))
});


function DatePicker(props) {
    const Lang = props.lang;
    const reduxStates = props.state_data.datepicker;
    const setReduxSelectedDate = (date) => {
        props.setReduxSelectedDate(date)
    }
    const onDateChange = useCallback((date) => {
        //setValue(date);
        setReduxSelectedDate(date);
    }, []);
    var monthlables = {
        '1': 'Januar',
        '2': 'Februar',
        '3': 'März',
        '4': 'April',
        '5': 'Mai',
        '6': 'Juni',
        '7': 'Juli',
        '8': 'August',
        '9': 'September',
        '10': 'Oktober',
        '11': 'November',
        '12': 'Dezember'
    }
    if(Lang == 'en')
    {
        monthlables = {
            '1': 'January',
            '2': 'February',
            '3': 'March',
            '4': 'April',
            '5': 'May',
            '6': 'June',
            '7': 'July',
            '8': 'August',
            '9': 'September',
            '10': 'October',
            '11': 'November',
            '12': 'December'
        }
    }


    return (
        <div>
            <SelectDatepicker
                selectedDate={reduxStates.selectedDate}
                onDateChange={onDateChange}
                minDate={reduxStates.minDate}
                maxDate={reduxStates.maxDate}
                reverseYears={true}
                hideLabels={true}
                order="day/month/year"
                className='tnddpicker'
                labels={{
                    months: monthlables,
                    monthPlaceholder: ' ',
                    dayPlaceholder: ' ',
                    yearPlaceholder: ' '
                }}
            />
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);