import { addDays, format } from 'date-fns';

const initialState = {
    selectedDate: new Date(),
    minDate: new Date(),
    maxDate: addDays(new Date(), 90)
};

export default function datepickerReducer(state = initialState, action) {
    switch (action.type) {
        case "selectedDate":
            return {
                ...state,
                selectedDate: action.payload
            };
     
        default:
            return state;
    }
}