import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { setCalendarRoomTypeAction, setCalendarPersonNumberAction, setCalendarCateringAction } from '../../actions/tnPriceCalendarAction';
import { useSelector } from 'react-redux';
import Language from '../../language/language.json';

const mapStateToProps = (state) => ({
    state_data: state,
    widgetSettings: state.global.widgetSettings,
    calendar_roomType: state.calendarfilter.calendar_roomType,
    calendar_personNumber: state.calendarfilter.calendar_personNumber,
    calendar_cateringId: state.calendarfilter.calendar_cateringId
})

const mapDispatchToProps = dispatch => ({
    setRoomType: (payload) => dispatch(setCalendarRoomTypeAction(payload)),
    setPersonNumber: (payload) => dispatch(setCalendarPersonNumberAction(payload)),
    setCateringId: (payload) => dispatch(setCalendarCateringAction(payload)),
});

export const CalendarFilter = (props) => {
    const [cateringOptions, setCateringOptions] = useState([]);
    const [roomTypeOptions, setRoomTypeOptions] = useState([]);

    useEffect(() => {        
        if (Object.keys(props.widgetSettings).length > 0 && cateringOptions.length === 0 ) {
            setCateringOptions(props.widgetSettings.params.caterings);
            setRoomTypeOptions(props.widgetSettings.params.resources);
        }
    }, [props.widgetSettings])

    const setRoomType = (e) => {
        props.setRoomType(e.target.value);
    }
    const setPersonNumber = (e) => {
        props.setPersonNumber(e.target.value);
    }
    const setCateringId = (e) => {
        props.setCateringId(e.target.value);
    }
    return (
        <>
            <div className='select_box tnpricelist-roomtype-select_box'>
                <select value={props.calendar_roomType} onChange={setRoomType}>
                    {Object.keys(roomTypeOptions).length > 0
                        ? roomTypeOptions.map((room) => {
                            return (
                                <option value={room.id} key={room.id}>{room.name}</option>
                            )
                        })
                        : <option value={0}>{Language[props.lang].loading}...</option>
                    }
                </select>
                <div className="bt_ico"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" className="svg-inline--fa fa-chevron-down " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"></path></svg></div>
            </div>

            <div className='select_box tnpricelist-persons-select_box'>
                <select value={props.calendar_personNumber} onChange={setPersonNumber}>
                    <option value={1}>1 p.</option>
                    <option value={2}>2 p.</option>
                    <option value={3}>3 p.</option>
                    <option value={4}>4 p.</option>
                </select>
                <div className="bt_ico"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" className="svg-inline--fa fa-chevron-down " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"></path></svg></div>
            </div>

            {Object.keys(cateringOptions).length > 0
            ?        
            <div className='select_box tnpricelist-breakfast-select_box'>
                <select
                    name='cateringId'
                    value={props.calendar_cateringId}
                    onChange={setCateringId}
                >
                    {Object.keys(cateringOptions).length > 0
                        ? cateringOptions.map((cat) => {
                            return (
                                <option value={cat.id} key={cat.id}>{cat.name}</option>
                            )
                        })
                        : <option value={0}>{Language[props.lang].loading}...</option>
                    }

                </select>
                <div className="bt_ico"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" className="svg-inline--fa fa-chevron-down " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"></path></svg></div>
            </div>
            : 
            ''}
            {
                props.isloading ? <div>{Language[props.lang].loadingmsg}</div> : ''            
            }

        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarFilter)