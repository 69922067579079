const initialState = {
    widgetSettings: []
}

function globalReducer(state = initialState, action) {
    switch (action.type) {
        case "widgetSettings":
            return {
                ...state,
                widgetSettings: action.payload
            };
     
        default:
            return state;
    }
}

export default globalReducer