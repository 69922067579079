import {combineReducers} from "redux";
import datepickerReducer from "./datepickerReducer";
import daterangeReducer from "./daterangeReducer";
import globalReducer from "./globalReducer";
import personpickerReducer from "./personpickerReducer";
import tnPriceCalendarReducer from "./tnPriceCalendarReducer";
import tnpriceviewReducer from "./tnpriceviewReducer";

const rootReducer = combineReducers({
    datepicker : datepickerReducer,
    daterange : daterangeReducer,
    personpicker : personpickerReducer,
    tnpriceview: tnpriceviewReducer,
    global: globalReducer,
    calendarfilter: tnPriceCalendarReducer
});

export default rootReducer;