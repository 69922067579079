const initialState = {
    roomType: 0,
    personNumber: 1,
    cateringId: 0
}
function personpickerReducer(state = initialState, action) {
    switch (action.type) {
        case "roomType":
            return {
                ...state,
                roomType: action.payload
            };

        case "personNumber":
            return {
                ...state,
                personNumber: action.payload
            };

        case "cateringId":
            return {
                ...state,
                cateringId: action.payload
            };
            
        default:
            return state;
    }
}

export default personpickerReducer