import { API_ROOT, default_host } from ".";

var api_url = API_ROOT + "/widgets"

const getWidgetSettings = async (host, bearerToken, id, module) => {

    if(host){
        api_url = api_url.replace('{host}',host);
    } else {
        api_url = api_url.replace('{host}',default_host);
    }
    
    const form_api_url = api_url + '/' + id;
    
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
    };

    try {
        const response = await fetch(form_api_url, requestOptions);
        const json = await response.json();
        return json;

    } catch (error) {
        console.log("error", error);
    }
};

export default getWidgetSettings;