import React, { useState, useEffect, useRef, useContext, Component } from 'react';
import PersonPicker from './PersonPicker';
import DatePicker from './DatePicker';
import { connect, useSelector } from 'react-redux';
import { setReduxWidgetSettingsAction } from '../actions/tnpriceviewAction';
import { setSelectedDateAction } from '../actions/datepickerAction';
import doAuth from './api/auth';
import getWidgetSettings from './api/widgetsettings';
import getPriceAvailableAll from './api/priceavailableall';
import 'reactjs-popup/dist/index.css';
import {setDefaultOptions, format, addDays, startOfMonth, sub, startOfWeek, parseISO, nextMonday, previousMonday, differenceInCalendarDays, addMonths } from 'date-fns';
//import format from "date-fns/fp/formatWithOptions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faAnglesLeft, faChevronRight, faAnglesRight, faCalendarPlus, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Language from '../language/language.json';


import { de } from 'date-fns/locale';


const mapStateToProps = (state) => ({
    state_data: state
})

const mapDispatchToProps = dispatch => ({
    setReduxWidgetSettings: (payload) => dispatch(setReduxWidgetSettingsAction(payload)),
    setSelectedDate: (payload) => dispatch(setSelectedDateAction(payload)),
    // setReduxCateringId: (payload) => dispatch(setCateringIdAction(payload)),
    // setReduxPersonNumber: (payload) => dispatch(setPersonNumberAction(payload)),
});

function TnPriceView(props) {
    const Lang = props.lang;
    if(Lang == 'de')
    {
        setDefaultOptions({ locale: de })
    }
    const [localRoomList, setLocalRoomList] = useState([]);
    const [postlocalRoomList, setPostlocalRoomList] = useState([], (postlocalRoomList) => setPostlocalRoomList(postlocalRoomList));
    const [localRoomAvailability, setLocalRoomAvailability] = useState([], (localRoomAvailability) => setLocalRoomAvailability(localRoomAvailability));
    const [authToken, setAuthToken] = useState([]);
    const [widgetSettings, setWidgetSettings] = useState([]);
    const [currentMonthYear, setCurrentMonthYear] = useState([format(new Date(), 'MM_yyyy_II')]);
    const [loadingApi, setLoadingApi] = useState(0);

    const roomrowRef = useRef([]);
    const pricerowRef = useRef([]);
    const sliderRef = useRef([]);
    const sliderslideRef = useRef([]);

    const today = new Date();
    const startofweek = today; //startOfWeek(today, { weekStartsOn: 1 });

    const day_arr = [];
    for (let i = 0; i < 90; i++) {
        day_arr.push(addDays(startofweek, i));
    }

    const setReduxWidgetSettings = (data) => {
        props.setReduxWidgetSettings(data);
        setWidgetSettings(data);
    }
    const dayBoxClicked = (e) => {
        var dayw_num = e.currentTarget.getAttribute("dayw_num");
        var day_year = e.currentTarget.getAttribute("day_year");
        var day_num = e.currentTarget.getAttribute("day_num");
        var day_month = e.currentTarget.getAttribute("day_month");
        var date_string = day_year + "-" + day_month + "-" + day_num + "T00:00:00";
        var date_iso = parseISO(date_string);
        props.setSelectedDate(date_iso);
    }
    const updateLocalRoomList = (data) => {
        setLocalRoomList(data);
    }


    useEffect(() => {
        if (authToken.length == 0) {
            (async () => {
                // get access token
                await doAuth(props.host, props.token, props.uuid).then((data) => {
                    localStorage.setItem('authtoken', JSON.stringify(data));
                });

                var authtoken = JSON.parse(localStorage.getItem('authtoken'));

                //get widget settings
                await getWidgetSettings(props.host, authtoken.token.token, authtoken.params.id, authtoken.params.module).then((data) => {
                    setReduxWidgetSettings(data);
                    var roomEntites = data.params.entities;
                    var roomResources = data.params.resources;
                    var localroomlist = { entites: roomEntites, resources: roomResources };
                    updateLocalRoomList(localroomlist);
                });

            })();
        }

    }, []);

    async function getPriceAvai(test) {
        if(loadingApi === 1)
        {
            return '';
        }
        if (Object.keys(localRoomList).length > 0) {
            setLoadingApi(1);
            var day_mm_yyyy_eeeeee_clean = format(props.state_data.datepicker.selectedDate, 'MM_yyyy_II');
            var postdata = {
                "start": format(props.state_data.datepicker.selectedDate, 'dd.MM.yyyy'), //format(new Date(), 'dd.M.yyyy'),
                "days": 7,
                "persons": props.state_data.personpicker.personNumber,
            }
            if (props.state_data.personpicker.cateringId > 0) {
                postdata = { ...postdata, "catering": parseInt(props.state_data.personpicker.cateringId) }
            }

            var authtoken = JSON.parse(localStorage.getItem('authtoken'));
            var returneddata;
            await getPriceAvailableAll(props.host, authtoken.token.token, authtoken.params.id, postdata).then((data) => {
                returneddata = data;
            });

            if (returneddata.error == undefined) {
                /*
                if (localRoomAvailability.length > 0) {
                    var old_localRoomAvailability = localRoomAvailability;
                    for (let i = 0; i < returneddata.length; i++) {
                        for (let j = 0; j < returneddata[i].dates.length; j++) {
                            old_localRoomAvailability[i].dates.push(returneddata[i].dates[j]);
                        }
                    }
                    returneddata = old_localRoomAvailability;
                }
                */
                setLocalRoomAvailability(returneddata);
                setPostlocalRoomList([]);
                setPostlocalRoomList(localRoomList);
                setCurrentMonthYear(current => [...current, day_mm_yyyy_eeeeee_clean]);
            }
            setLoadingApi(0);
        }

    }


    // useEffect(() => {
    //     var personNumber = props.state_data.personpicker.personNumber;
    //     Object.keys(roomrowRef.current).map((key) => {
    //         var e_roomrow = roomrowRef.current[key];
    //         var occupancy_max = e_roomrow.getAttribute("occupancy_max");
    //         if (parseInt(occupancy_max) > parseInt(personNumber) || parseInt(occupancy_max) === parseInt(personNumber)) {
    //             e_roomrow.classList.remove('tnhideme')
    //         }
    //         else {
    //             e_roomrow.classList.add('tnhideme')
    //         }
    //     });

    //     Object.keys(pricerowRef.current).map((key) => {
    //         var e_pricerow = pricerowRef.current[key];
    //         var occupancy_max = e_pricerow.getAttribute("occupancy_max");
    //         if (parseInt(occupancy_max) > parseInt(personNumber) || parseInt(occupancy_max) === parseInt(personNumber)) {
    //             e_pricerow.classList.remove('tnhideme')
    //         }
    //         else {
    //             e_pricerow.classList.add('tnhideme')
    //         }
    //     });


    // }, [props.state_data.personpicker.personNumber]);


    useEffect(() => {
        getPriceAvai("ForCateringAndPerson");
    }, [localRoomList, props.state_data.personpicker.cateringId, props.state_data.personpicker.personNumber]);


    useEffect(() => {
        if (props.state_data.datepicker.selectedDate) {
            var dayddmmyyy_clean = format(props.state_data.datepicker.selectedDate, 'ddMMyyyy');
            var ele = sliderslideRef.current[dayddmmyyy_clean];
            if (ele) {
                var ele_index = ele.getAttribute('indexnum');
                sliderRef.current.slickGoTo(ele_index);
            }
            var day_mm_yyyy_eeeee_clean = format(props.state_data.datepicker.selectedDate, 'MM_yyyy_II');
            //console.log(currentMonthYear);
            //if (!currentMonthYear.includes(day_mm_yyyy_eeeee_clean)) {
                getPriceAvai("ForSelectedDate");
            //}
        }

    }, [props.state_data.datepicker.selectedDate])


    const showSkeleton = () => {
        var scarr = [];
        for (let i = 0; i < 7; i++) {
            scarr.push(
                <div key={"scal" + i} className="tn-pricelist-dateholder-skeleton skeleton-loader">
                </div>
            );
        }
        return scarr;
    }

    var slidersettings = {
        dots: false,
        infinite: false,
        speed: 7,
        slidesToShow: 7,
        slidesToScroll: 7,
        focusOnSelect: true,
        arrows: false,
        draggable: false
    };

    const changeWeek = (e) => {
        //var weeknum = format(props.state_data.datepicker.selectedDate, 'I');
        var newdate = props.state_data.datepicker.selectedDate;
        var atype = e.currentTarget.getAttribute('atype');
        var diff;
        if (atype == 'next') {
            var nextmonday = nextMonday(props.state_data.datepicker.selectedDate)
            diff = differenceInCalendarDays(props.state_data.datepicker.maxDate, nextmonday);
            if (diff > 0) {
                newdate = nextmonday;
            }

        }
        else {
            var previousmonday = previousMonday(props.state_data.datepicker.selectedDate)
            diff = differenceInCalendarDays(previousmonday, props.state_data.datepicker.minDate);
            if (diff > 0) {
                newdate = previousmonday;
            }
        }
        props.setSelectedDate(newdate)
    }
    const changeMonth = (e) => {
        var newdate = props.state_data.datepicker.selectedDate;
        var atype = e.currentTarget.getAttribute('atype');
        var diff;
        if (atype == 'next') {
            var nextmonth = startOfMonth(addMonths(newdate, 1));
            diff = differenceInCalendarDays(props.state_data.datepicker.maxDate, nextmonth);
            if (diff > 0) {
                newdate = nextmonth;
            }
        }
        else {
            var previousmonth = startOfMonth(addMonths(newdate, -1));
            diff = differenceInCalendarDays(previousmonth, props.state_data.datepicker.minDate);
            if (diff > 0) {
                newdate = previousmonth;
            }
        }
        props.setSelectedDate(newdate)
    }

    //console.log(props.state_data.datepicker.selectedDate);
    var selectedDate_ddmmyyy = format(new Date(), 'yyyy-MM-dd');
    if(props.state_data.datepicker.selectedDate)
    {
        selectedDate_ddmmyyy = format(props.state_data.datepicker.selectedDate, 'yyyy-MM-dd');
    }
    const bookingLink = props.buchenlink+"?arrival="+selectedDate_ddmmyyy+"&departure="+selectedDate_ddmmyyy;
    const inquiryLink = props.anfragenlink+"?arrival="+selectedDate_ddmmyyy+"&departure="+selectedDate_ddmmyyy;


    return (
        <>
            {/* <TnInquiry  lang='de' token='' uuid='36d61457-a5da-483d-81d5-bb0bbbb933c1' color='red' ></TnInquiry> */}
            <div className='pricelist_box'>
                <div className='pricelist_box_top'>
                    <h3 className='pricelist_box_title'>{Language[Lang].ArrivalDate}</h3>
                    <div className={loadingApi===1 ? "select_box_area disabledsection" : "select_box_area"}>

                        <div className='select_box_area_left'>
                            <PersonPicker ispriceview="yes" lang={Lang} />
                        </div>

                    </div>
                </div>

                <div className={loadingApi===1 ? "pricelist_box_datepicker disabledsection" : "pricelist_box_datepicker"}>
                    <div className='tnpl-weekbtn-left'>
                        <button className='tnpl-weekbtn-left-btn-month' onClick={changeMonth} atype="prev">
                            <FontAwesomeIcon icon={faAnglesLeft} />
                        </button>
                        <button className='tnpl-weekbtn-left-btn-week' onClick={changeWeek} atype="prev">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                    </div>
                    <DatePicker lang={Lang}></DatePicker>
                    <div className='tnpl-weekbtn-right'>
                        <button className='tnpl-weekbtn-right-btn-month' onClick={changeWeek} atype="next">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                        <button className='tnpl-weekbtn-right-btn-week' onClick={changeMonth} atype="next">
                            <FontAwesomeIcon icon={faAnglesRight} />
                        </button>
                    </div>
                </div>

                <div className='pricelist_box_middle_roomview'>
                    <div className='pricelist_box_middle_left'>
                        {Object.keys(localRoomList).length > 0
                            ?
                            localRoomList.resources.map((room, index) => {
                                //console.log(room);
                                const { category, id, name, occupancy_max, occupancy_min, image } = room;
                                var key = id + "-" + index;
                                return (
                                    <div
                                        className='tnprice_roominfo'
                                        key={"room" + key}
                                        occupancy_max={occupancy_max}
                                        occupancy_min={occupancy_min}
                                        ref={(element) => roomrowRef.current[id] = element}
                                    >
                                        <div className='tnprice_roominfo_left'>
                                            <img width={55} height={44} src={image.url} />
                                            <div className='tnprice_roomname'>{name + " (" + occupancy_min + " - " + occupancy_max + " P.)"}</div>
                                        </div>
                                        <div className='tnprice_roominfo_right'>
                                            <a
                                                href={bookingLink+"&resource_id="+id}
                                                target="_blank"
                                                className='tnprice_booking_btn'>
                                                <FontAwesomeIcon icon={faCalendarPlus} />
                                                {Language[Lang].Booking}
                                            </a>
                                            <a
                                                href={inquiryLink+"&resource_id="+id}
                                                target="_blank"
                                                className='tnprice_inq_btn'>
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                                {Language[Lang].Inquiry}
                                            </a>
                                        </div>
                                    </div>
                                )
                            })
                            : "No rooms"
                        }

                    </div>

                    {/* Price column slider */}
                    <div className={loadingApi===1 ? "pricelist_box_middle_right disabledsection" : "pricelist_box_middle_right"}>
                        <div className='dayBox'>
                            <Slider {...slidersettings} ref={sliderRef}>
                                {(Object.keys(postlocalRoomList).length) > 0
                                    ?
                                    day_arr.map((day, i) => {
                                        const daydd = format(day, 'dd');
                                        const dayw = format(day, 'EEEEEE');
                                        const dayddmmyyy = format(day, 'dd.MM.yyyy');

                                        const dayw_num = format(day, 'I');
                                        const day_year = format(day, 'Y');
                                        const day_num = format(day, 'dd');
                                        const day_month = format(day, 'MM');

                                        const dayddmmyyy_clean = format(day, 'ddMMyyyy');

                                        var key = daydd + "-" + i;
                                        var keyroom = + "-" + i;
                                        return (

                                            <div
                                                className='tn-pricelist-dateholder'
                                                key={key}
                                                onClick={dayBoxClicked}
                                                dayw_num={dayw_num}
                                                day_year={day_year}
                                                day_num={day_num}
                                                day_month={day_month}
                                                indexnum={i}
                                                ref={(element) => sliderslideRef.current[dayddmmyyy_clean] = element}
                                            >
                                                <div className='tn-pricelist-selecteddate' seldate={key}>
                                                    <div>{dayw}</div>
                                                    <div>{daydd} </div>
                                                </div>
                                                {postlocalRoomList.resources.map((room, j) => {
                                                    var id = room.id;
                                                    var key_in = key + "-" + j;
                                                    var dayclass = "day_disav skeleton-loader";
                                                    var date_arr = [];
                                                    var date_price = "";
                                                    if (localRoomAvailability.length > 0) {

                                                        var res_arr = localRoomAvailability.find((res) => res.resource_id == id);
                                                        date_arr = res_arr.dates.find((datesin) => datesin.date == dayddmmyyy.toString());

                                                        if (date_arr != undefined && date_arr.available > 0) {
                                                            dayclass = "day_yesav";
                                                            date_price = date_arr.price
                                                        }
                                                        else if (date_arr != undefined && date_arr.available === 0) {
                                                            dayclass = "day_noav";
                                                        }
                                                    }

                                                    return (
                                                        <div
                                                            className={dayclass + " " + id}
                                                            key={key_in}
                                                            occupancy_max={room.occupancy_max}
                                                            occupancy_min={room.occupancy_min}
                                                            ref={(element) => pricerowRef.current[id + "_" + dayddmmyyy_clean] = element}
                                                        >
                                                            {date_price}
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>

                                        )

                                    })
                                    : showSkeleton()
                                }
                            </Slider>
                        </div>

                    </div>
                </div>
                <div className='pricelist_box_bottom'>
                    <p>{Language[Lang].ListFooterText}</p>
                </div>
                <div className='pricelist_box_bottom_two'>
                    <div>
                        <span className='sign_booked'></span>
                        <span>{Language[Lang].Booked}</span>
                    </div>
                    <div>
                        <span className='sign_available'></span>
                        <span>{Language[Lang].Available}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

TnPriceView.propTypes = {}

export default connect(mapStateToProps, mapDispatchToProps)(TnPriceView)
