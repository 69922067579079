import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { setRoomTypeAction, setPersonNumberAction, setCateringIdAction } from '../../actions/personpickerAction';
import Language from '../../language/language.json';

const mapStateToProps = (state) => ({
    state_data: state
})

const mapDispatchToProps = dispatch => ({
    setRoomType: (payload) => dispatch(setRoomTypeAction(payload)),
    setPersonNumber: (payload) => dispatch(setPersonNumberAction(payload)),
    setCateringId: (payload) => dispatch(setCateringIdAction(payload)),
});

export const PersonPicker = (props) => {
    const Lang = props.lang;
    const [cateringOptions, setCateringOptions] = useState([]);
    useEffect(() => {
        if (Object.keys(props.state_data.tnpriceview.widgetSettings).length > 0 && cateringOptions.length === 0 ) {
            setCateringOptions(props.state_data.tnpriceview.widgetSettings.params.caterings);
            if(props.state_data.tnpriceview.widgetSettings.params.caterings.length > 0){
                props.setCateringId(props.state_data.tnpriceview.widgetSettings.params.caterings[0].id);
            }
        }
    }, [props.state_data.tnpriceview.widgetSettings])


    const state_data = props.state_data;

    const setRoomType = (e) => {
        props.setRoomType(e.target.value);
    }
    const setPersonNumber = (e) => {
        props.setPersonNumber(e.target.value);
    }
    const setCateringId = (e) => {
        props.setCateringId(e.target.value);
    }
    return (
        <>
            <div className='select_box tnpricelist-persons-select_box'>
                <select 
                name='personNumber'
                value={state_data.personNumber} 
                onChange={setPersonNumber}>
                    <option value={1}>1 p.</option>
                    <option value={2}>2 p.</option>
                    <option value={3}>3 p.</option>
                    <option value={4}>4 p.</option>
                </select>
                <div className="bt_ico"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" className="svg-inline--fa fa-chevron-down " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"></path></svg></div>
            </div>

            {Object.keys(cateringOptions).length > 0
            ?
            <div className='select_box tnpricelist-breakfast-select_box'>
                <select
                    name='cateringId'
                    defaultValue={state_data.cateringId}
                    onChange={setCateringId}
                >
                    {Object.keys(cateringOptions).length > 0
                        ? cateringOptions.map((cat, index) => {
                            return (
                                <option value={cat.id} key={cat.id}>{cat.name}</option>
                            )
                        })
                        : <option value={0}>{Language[Lang].loadingmsg}...</option>
                    }

                </select>
                <div className="bt_ico"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" className="svg-inline--fa fa-chevron-down " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"></path></svg></div>
            </div>
            : 
            ''}


        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonPicker)