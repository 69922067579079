export const setRoomTypeAction = (payload) => {
    return {
        type: "roomType",
        payload
    }
};

export const setPersonNumberAction = (payload) => {
    return {
        type: "personNumber",
        payload
    }
};

export const setCateringIdAction = (payload) => {
    return {
        type: "cateringId",
        payload
    }
};
