export const setCalendarRoomTypeAction = (payload) => {
    return {
        type: "calendar_roomType",
        payload
    }
};

export const setCalendarPersonNumberAction = (payload) => {
    return {
        type: "calendar_personNumber",
        payload
    }
};

export const setCalendarCateringAction = (payload) => {
    return {
        type: "calendar_cateringId",
        payload
    }
};

export const setCalendarAvailabilityAction = (payload) => {
    return {
        type: "calendar_availability",
        payload
    }
}

