const queryParams = new URLSearchParams(window.location.search);
const resource_id = queryParams.get('resource_id');
const persons = queryParams.get('persons');
const catering = queryParams.get('catering');

const initialState = {
    calendar_roomType: (resource_id) ? resource_id : 0,
    calendar_personNumber: (persons) ? persons : 1,
    calendar_cateringId: (catering) ? catering : 0,
    calendar_availability:[]
};
export default function tnPriceCalendarReducer(state = initialState, action) {
    
    switch (action.type) {

        case "calendar_roomType":
            return {
                ...state,
                calendar_roomType: action.payload
            };

        case "calendar_personNumber":
            return {
                ...state,
                calendar_personNumber: action.payload
            };

        case "calendar_cateringId":
            return {
                ...state,
                calendar_cateringId: action.payload
            };

        case "calendar_availability":
            return {
                ...state,
                calendar_availability: action.payload
            };          
            
        default:
            return state;
    }
}