import { API_ROOT, default_host } from ".";
var api_url = API_ROOT + "/auth/token";
const doAuth = async (host, token, vendor_uuid) => {

    if(host){
        api_url = api_url.replace('{host}',host);
    } else {
        api_url = api_url.replace('{host}',default_host);
    }

    const postData = {
        token: token,
        vendor_uuid: vendor_uuid,
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData)
    };

    try {
        const response = await fetch(api_url, requestOptions);
        const json = await response.json();
        return json;
    } catch (error) {
        console.log("error", error);
    }
};

export default doAuth;